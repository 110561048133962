<template>
  <div class="update">
    <h1>上传作品 <span></span></h1>
    <div class="content">
      <div class="top">
        <div class="left">
          <div class="txtbox">
            <h3>视频要求：</h3>
            <p>1、清晰无抖动，保证评委能准确识别参赛者的操作动作；</p>
            <p>2、按照16:9格式拍摄（手机横屏拍摄）；</p>
            <p>3、视频时长20分钟以内；</p>
          </div>
          <div class="txtbox">
            <h3>脱敏处理：</h3>
            <p>1、提交的作品中原则上只允许参赛者一人出镜，其他与赛事无关人员 无需出镜；</p>
            <p>2、必须隐去参赛者姓名、身份信息和医院名称；</p>
          </div>
        </div>
        <div class="right">
          <h2>视频上传</h2>
          <p>*建议参赛者自行上传的视频资料最好由所在单位审核、把关后再上传</p>
        </div>
      </div>
      <div class="bottom">
        <div class="title">视频简介</div>
        <div class="textarea">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请描述视频内容"
            v-model="textarea"
          >
          </el-input>
        </div>
      </div>
    </div>
    <div class="footer">
      <footerCard></footerCard>
    </div>
  </div>
</template>
<script>
import footerCard from '../../components/footerCard.vue'

export default {
  data() {
    return {
      textarea: ''
    };
  },
  methods: {

  },
  components: {
    footerCard
  }
}
</script>
<style scoped lang="scss">
.update {
  padding: 50px 0 0 0;
  background-image: url("../../assets/image/index-img/bg.webp"),
    url("../../assets/image/index-img/bg2.webp");
  background-position: top, bottom;
  background-repeat: no-repeat, no-repeat;
  h1 {
    font-size: 26px;
    text-align: center;
    color: #5b2d9b;
    line-height: 54px;
    span {
      display: block;
      margin: 0px auto;
      width: 24px;
      height: 4px;
      background: #5b2d9b;
      border-radius: 2px;
    }
  }
  .content {
    width: 1400px;
    background-color: #fff;
    box-shadow: 10px 0px 32px 10px rgb(0 0 0 / 8%);
    border-radius: 4px;
    margin: 0 auto;
    margin-top: 30px;
    padding: 40px 64px;
    .top {
      display: flex;
      .left {
        width: 50%;
        padding-right: 150px;
        box-sizing: border-box;
        .txtbox {
          padding-bottom: 30px;
          font-size: 18px;
          h3 {
            color: #49484f;
            padding-bottom: 15px;
          }
          p {
            color: #8f8f8f;
            // padding-bottom: 20px;
            line-height: 40px;
          }
        }
      }
      .right {
        width: 50%;
        h2 {
          font-size: 22px;
          color: #5b2d9b;
        }
        p {
          padding: 18px 0 25px 0;
        }
      }
    }
    .bottom {
      height: 200px;
      padding-top: 25px;
      display: flex;
      padding-bottom: 50px;
      .title {
        padding-right: 35px;
        color: #161517;
        font-size: 22px;
      }
      .textarea {
        flex: 1;
        height: 100%;
        .el-textarea {
          border-radius: 4px;
          height: 100%;
        }
        ::v-deep .el-textarea__inner {
          height: 100%;
          background: #f8f8f8;
          padding: 10px;
        }
      }
    }
  }
  .footer {
    padding-top: 120px;
  }
}
</style>